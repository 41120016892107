
import './App.css';
import Index from './App/Index';

function App() {
  return (
 <>
 <Index/>
 </>   
 
  );
}

export default App;
